export class LvDateUtil {

  /**
   * monthDiff checks for difference in months betweene given dates
   * @param to is this.dateTo
   * @param from is this.dateFrom
   * @returns difference in months between these dates
   */
  public static monthDiff(to: any, from: any): any {
    const toDate = new Date(to);
    const fromDate = new Date(from);

    const diff = (toDate.getFullYear() * 12 + toDate.getMonth()) - (fromDate.getFullYear() * 12 + fromDate.getMonth());
    return diff;
  }

  /**
   * isValidDate method checks if sent date is less than minDate or null
   * value of minDate has value of min date sent from backend
   * it prevents sending request on backend if date does not fullfill conditions
   * @param value date value
   * @returns flag that describes if provided date is valid
   */
  public static isValidDate(value: any): boolean {
    const minDate = new Date('1/1/1753');
    if (!value) {
      return false;
    }
    if (value < minDate) {
      return false;
    }
  }

  /**
   * Parse date time.
   * @param value Date value in string.
   * @returns Date object from string date.
   */
  public static parse(value?: string | Date): Date {
    if (!value) {
      return null;
    }

    //This part code is using only from open in excel
    if (value instanceof Date) {
      return  new Date(
        new Date(value).getUTCFullYear(),
        new Date(value).getUTCMonth(),
        new Date(value).getUTCDate(),
        new Date(value).getUTCHours(),
        new Date(value).getUTCMinutes(),
        new Date(value).getUTCSeconds()
      );
    }

    // Try parsing as ISO 8601 with timezone
    let date = new Date(
      new Date(value).getUTCFullYear(),
      new Date(value).getUTCMonth(),
      new Date(value).getUTCDate(),
      new Date(value).getUTCHours(),
      new Date(value).getUTCMinutes(),
      new Date(value).getUTCSeconds()
    );

    if (!isNaN(date.getTime())) {
      date.setHours(0, 0, 0, 0);
      return date;
    }

    // Try parsing as ISO 8601 without timezone
    date = new Date(`${value}Z`);
    if (!isNaN(date.getTime())) {
      date.setHours(0, 0, 0, 0);
      return date;
    }

    // Try parsing as short date string
    date = new Date(value.replace(/-/g, '/'));
    if (!isNaN(date.getTime())) {
      date.setHours(0, 0, 0, 0);
      return date;
    }

    // Try parsing as long date string
    const dateTimeParts = value.split('/');
    if (dateTimeParts.length === 3) {
      const month = parseInt(dateTimeParts[0]) - 1;
      const day = parseInt(dateTimeParts[1]);
      const year = parseInt(dateTimeParts[2]);

      date = new Date(year, month, day);
      if (!isNaN(date.getTime())) {
        date.setHours(0, 0, 0, 0);
        return date;
      }
    }

    return null;
  }

  /**
   * Parse date time string to Date object.
   * @param value Date time value in string format.
   * @returns 
   */
  public static parseDateTime(value?: string | Date): Date {
    if (!value) {
      return null;
    }

    if (value instanceof Date) {
      return value;
    }

    if (!value.endsWith('Z')) {
      value = value + 'Z';
    }

    const date = new Date(value);

    if (!isNaN(date.getTime())) {
      return date;
    }

    return null;
  }

  /**
   * Convert date to utc date.
   * @param value Date value.
   * @returns Date in utc.
   */
  public static toUtcDate(value?: string | Date): Date | null {
    if (!value) {
      return null;
    }

    if (value instanceof Date) {

      const utcDate = LvDateUtil.getUtcDateFromDate(value);

      return new Date(utcDate);
    }

    // Try parsing the string value to Date
    const date = new Date(value);

    if (!isNaN(date.getTime())) {

      const utcDate = LvDateUtil.getUtcDateFromDate(date);

      return new Date(new Date(utcDate));
    }

    // If parsing fails, return null or handle as needed
    return null;
  }

  /**
   * Convert date to iso string value.
   * @param value Date value.
   * @returns Date represented in iso string format.
   */
  public static toISODateTimeString(value?: string | Date): string | null {
    if (!value) {
      return null;
    }

    if (value instanceof Date) {
      // If the input is already a Date, use toISOString with time included
      const hours = String(value.getHours()).padStart(2, '0');
      const minutes = String(value.getMinutes()).padStart(2, '0');
      return `1970-01-01T${hours}:${minutes}:00Z`;
    }

    // Try parsing the string value to Date
    const date = new Date(value);

    if (!isNaN(date.getTime())) {
      const hours = String(date.getHours()).padStart(2, '0');
      const minutes = String(date.getMinutes()).padStart(2, '0');
      return `1970-01-01T${hours}:${minutes}:00Z`;
    }

    // If parsing fails, return null or handle as needed
    return null;
  }

  /**
   * Calculates year differences between two dates.
   * @param firstDateToCompare First date to compare.
   * @param secondDateToCompare Second date to compare.
   * @returns Years difference.
   */
  public static calculateYearsFromDate(firstDateToCompare: Date, secondDateToCompare: Date): number | null {
    if (!firstDateToCompare || !secondDateToCompare) {
      return null;
    }

    firstDateToCompare = new Date(firstDateToCompare);
    secondDateToCompare = new Date(secondDateToCompare);

    const utcFirstDate = new Date(Date.UTC(firstDateToCompare.getUTCFullYear(), firstDateToCompare.getUTCMonth(), firstDateToCompare.getUTCDate()));
    const utcSecondDate = new Date(Date.UTC(secondDateToCompare.getUTCFullYear(), secondDateToCompare.getUTCMonth(), secondDateToCompare.getUTCDate()));

    const millisecondsDiff = utcFirstDate.getTime() - utcSecondDate.getTime();
    const yearsDiff = millisecondsDiff / (365.25 * 24 * 60 * 60 * 1000);

    return parseFloat(yearsDiff.toFixed(2));
  }

  
  /**
   * Create UTC data from utc string without any time zone shift.
   *
   * @static
   * @param {string} value
   * @return {*}  {Date}
   * @memberof LvDateUtil
   */
  public static getUtcDataFromIsoString(value: string): Date {
    return new Date(
      new Date(value).getUTCFullYear(),
      new Date(value).getUTCMonth(),
      new Date(value).getUTCDate(),
      new Date(value).getUTCHours(),
      new Date(value).getUTCMinutes(),
      new Date(value).getUTCSeconds()
    );
  }


  /**
   * Calculate date value based on years added to the firstDate to compare
   * @param yearInDecimal Decimal number of years
   * @param firstDateToCompare date on which will be added yearInDecimal
   * @returns Date value
   */
  public static calculateDateFromDecimalString(yearInDecimal: number, firstDateToCompare: Date): Date {
    const today = new Date();
    //If first date isn't written, it will use todays date
    const mFirst = firstDateToCompare ? this.parse(new Date(firstDateToCompare)) : this.parse(today);

    const splitedValue = yearInDecimal.toString().split('.');
    const yearShift = parseInt(splitedValue[0]);
    const daysShift = splitedValue[1] ? 365 * parseFloat("0." + splitedValue[1]) : 0;
    const fullYear = new Date(mFirst.setFullYear(mFirst.getFullYear() + yearShift));
    const date = new Date(fullYear.setDate(fullYear.getDate() + Math.round(daysShift)));

    return date;
  }

  /**
   * Calculate decimal year value based on substruction of two dates
   * @param firstDateToCompare First date to compare
   * @param secondDateToCompare Date that will be substructed from firstDateToCompare
   * to calculate decimal number of years
   * @returns decimal value of years
   */
  public static calculateDecimalFromDate(firstDateToCompare: Date, secondDateToCompare: Date): number {
    if (!firstDateToCompare || !secondDateToCompare) {
      return null;
    }

    const baseDate = this.getUtcDateFromDate(new Date(secondDateToCompare));
    const dateToCompare = this.getUtcDateFromDate(new Date(firstDateToCompare));

    const { leapYears, regularYears } = this.countLeapAndRegularYears(baseDate, dateToCompare);

    const daysDiff = (dateToCompare.getTime() - baseDate.getTime()) / (1000 * 60 * 60 * 24);
    
    const numberOfDaysInYears = leapYears * 366 + regularYears * 365;
    const yearDiff = leapYears + regularYears;

    const decimalResult = (yearDiff + (daysDiff - numberOfDaysInYears) / 365).toFixed(4);
    
    return parseFloat(decimalResult);
  }

  public static countLeapAndRegularYears(startDate: Date, endDate: Date): { leapYears: number, regularYears: number } {
    const startYear = startDate.getFullYear();
    const endYear = endDate.getFullYear();
  
    let leapYears = 0;
    let regularYears = 0;
  
    for (let year = startYear; year < endYear; year++) {
      if ((year % 4 === 0 && year % 100 !== 0) || (year % 400 === 0)) {
        leapYears++;
      } else {
        regularYears++;
      }
    }
  
    return { leapYears, regularYears };
  }

  private static getUtcDateFromDate(date: Date) {
    date.setHours(0, 0, 0, 0);

    const result = Date.UTC(
      date.getFullYear(),
      date.getMonth(),
      date.getDate(),
      date.getHours(),
      date.getMinutes(),
      date.getSeconds(),
      date.getMilliseconds()
    );

    return new Date(result)
  }
}
